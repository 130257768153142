import { useEffect, useMemo } from "react";

import { ModelStatusTypes } from "@prisma/client";
import { type NextPage } from "next";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";

import BlogPosts from "@musicfy/components/Dashboard/BlogPosts";
import Carousel from "@musicfy/components/Dashboard/Carousel";
import CreateVoice from "@musicfy/components/Dashboard/CreateVoice";
import ModelsCarousel from "@musicfy/components/Dashboard/ModelsCarousel";
import RecentlyCreated from "@musicfy/components/Dashboard/RecentlyCreated";
import Welcome from "@musicfy/components/Dashboard/Welcome";
import { useModels } from "@musicfy/libs/ModelsProvider/hooks";
import { type IModel } from "@musicfy/libs/ModelsProvider/IModels";
import { isModelTrending } from "@musicfy/libs/ModelsProvider/utils";
import { usePaddleContext } from "@musicfy/libs/PaddleProvider";
import { useRequireAuthContext } from "@musicfy/libs/RequireAuthProvider";

const Dashboard: NextPage = () => {
  const router = useRouter();
  const { status } = useSession();

  const { models } = useModels();
  const { startCheckoutFlow } = usePaddleContext();
  const { showAuthModal } = useRequireAuthContext();

  const trendingModels = useMemo(
    () =>
      models
        .filter(
          (model: IModel) =>
            !model.isInstrumental &&
            model.status === ModelStatusTypes.completed &&
            !!model.usageCount &&
            model.usageCount > 0 &&
            !model.isPrivate &&
            isModelTrending(model)
        )
        .sort((a, b) => (b?.trendingScore || 0) - (a?.trendingScore || 0))
        .slice(0, 20),
    [models]
  );

  const popularModels = useMemo(
    () =>
      models
        .filter(
          (model: IModel) =>
            !model.isInstrumental &&
            model.status === ModelStatusTypes.completed &&
            !!model.usageCount &&
            model.usageCount > 0 &&
            !model.isPrivate &&
            model.isCopyrightFree
        )
        .sort((a, b) => (b?.usageCount || 0) - (a?.usageCount || 0))
        .slice(0, 20),
    [models]
  );

  const parodyModels = useMemo(
    () =>
      models
        .filter(
          (model: IModel) =>
            !model.isInstrumental &&
            model.status === ModelStatusTypes.completed &&
            !!model.usageCount &&
            model.usageCount > 0 &&
            !model.isPrivate &&
            !model.isCopyrightFree &&
            !model.isInstrumental
        )
        .sort((a, b) => (b?.usageCount || 0) - (a?.usageCount || 0))
        .slice(4, 24),
    [models]
  );

  useEffect(() => {
    if (router.query.show_pricing) {
      if (status === "loading") {
        return;
      }
      if (status === "unauthenticated") {
        showAuthModal();
        return;
      } else {
        startCheckoutFlow("black-friday-sale-banner");
      }
    }
  }, [router.query.show_pricing, showAuthModal, startCheckoutFlow, status]);

  return (
    <div>
      <Carousel />
      <div className="w-full h-[60vh] lg:h-[70vh] lg:min-h-[400px] lg:max-h-[600px] overflow-hidden" />
      <div className="w-full -mt-28 z-30 relative">
        <div className="w-full grid xl:grid-cols-7 grid-cols-1 gap-3">
          <div className="flex flex-col gap-3 xl:col-span-4">
            <Welcome />
            <CreateVoice />
          </div>
          <div className="rounded-[18px] border border-white/15 bg-purple/5 backdrop-blur-xl xl:px-12 xl:py-8 p-6 xl:col-span-3 overflow-y-scroll">
            <RecentlyCreated />
          </div>
        </div>
      </div>
      <div className="mt-12">
        <ModelsCarousel models={trendingModels} title="Trending Voices" />
      </div>
      <div className="mt-12">
        <ModelsCarousel models={popularModels} title="Popular Voices" />
      </div>
      <div className="mt-12">
        <ModelsCarousel models={parodyModels} title="Parody Voices" />
      </div>
      <div className="mt-9">
        <BlogPosts />
      </div>
    </div>
  );
};

export default Dashboard;
