import { useMemo } from "react";

import { useSession } from "next-auth/react";

import Button from "@musicfy/components/Button";
import { FEATURE_ROUTES } from "@musicfy/components/Navigation/constants";
import RecentGenerations from "@musicfy/components/RecentGenerations";
import { useAudioGenerationsContext } from "@musicfy/libs/AudioGenerationsProvider";
import { type TAudioGeneration } from "@musicfy/libs/AudioGenerationsProvider/IAudioGenerations";
import { useMusicGenerationsContext } from "@musicfy/libs/MusicGenerationsProvider";

function isGenerationAudio(
  generation: unknown
): generation is TAudioGeneration {
  return !!(generation as TAudioGeneration).audioId;
}

const RecentlyCreated = (): JSX.Element => {
  const { audioGenerations } = useAudioGenerationsContext();
  const { musicGenerations } = useMusicGenerationsContext();

  const { data } = useSession();

  const combinedGenerations = useMemo(() => {
    return [...audioGenerations, ...musicGenerations].sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
  }, [audioGenerations, musicGenerations]);

  const recentGenerations = useMemo(() => {
    return combinedGenerations
      .filter((generation) => generation.status === "completed")
      .slice(0, 5)
      .map((generation) => {
        if (isGenerationAudio(generation)) {
          return {
            id: generation.id,
            source: generation.url,
            title: generation.model?.artist || "Unknown Artist",
            label: generation.type,
            subtitle: generation.filename,
          };
        }
        return {
          id: generation.id,
          source: generation.url,
          title: data?.user.name || "Unknown Artist",
          label: "Music AI",
          subtitle: generation.prompt,
        };
      });
  }, [combinedGenerations, data?.user.name]);

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="font-semibold lg:text-28 text-22 w-fit whitespace-nowrap">
          Recently Created
        </div>
        <Button
          path={FEATURE_ROUTES.history}
          className="ml-auto"
          variant="text"
        >
          View All
        </Button>
      </div>
      <RecentGenerations
        rowClassName="!bg-white/5 xl:hover:!bg-white/10 border border-white/10 xl:hover:border-white/20"
        selectedRowClassName="!border-pink-100"
        generations={recentGenerations}
      />
    </>
  );
};

export default RecentlyCreated;
