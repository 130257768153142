import { useCallback, useEffect, useState } from "react";

import { ArrowForward } from "@mui/icons-material";
import Image from "next/image";

type TBlogPost = {
  title: string;
  description: string;
  image: string;
  content: string;
  datePublished: string;
  link: string;
};

const BASE_CORS = "https://corsproxy.io/?";
const MAX_BLOG_POSTS = 3;

const RANDOM_COLORS = ["#823E5199", "#D9D9D933", "#78559B99"];

const BlogPostCard = ({ post }: { post: TBlogPost }): JSX.Element => {
  const randomColor =
    RANDOM_COLORS[Math.floor(Math.random() * RANDOM_COLORS.length)];
  return (
    <a
      href={post.link}
      target="_blank"
      rel="noreferrer"
      className="rounded-12 group flex cursor-pointer bg-gray-1000/50 relative overflow-hidden border border-white/10"
    >
      <div className="w-full h-full backdrop-blur-3xl absolute left-0 top-0 z-20" />
      <div
        style={{
          backgroundColor: randomColor,
        }}
        className="absolute transition-all group-hover:right-1/4 duration-500 bottom-2 right-4 w-20 aspect-square rounded-full z-10"
      />

      <Image
        src={post.image}
        alt={`${post.title} image`}
        width={300}
        height={300}
        className="object-cover h-48 w-[45%] rounded-r-12 relative z-30"
      />
      <div className="w-full relative z-30 p-4 lg:p-6 flex flex-col">
        <div className="text-14 font-semibold bg-gradient-to-br text-transparent bg-clip-text from-pink-200 to-pink-400">
          {post.title}
        </div>
        <div className="mt-2 text-16 font-bold line-clamp-2">
          {post.description}
        </div>
        <div className="text-12 text-gray-500 line-clamp-2 mt-2">
          {post.content}
        </div>
        <div className="mt-auto flex items-center justify-between">
          <div className="text-12 tracking-wide font-light">
            {post.datePublished}
          </div>

          <ArrowForward className="!text-20" />
        </div>
      </div>
    </a>
  );
};

const BlogPosts = (): JSX.Element | null => {
  const [blogPosts, setBlogPosts] = useState<TBlogPost[] | null>(null);

  const fetchAndParseBlogPosts = useCallback(async () => {
    const posts: TBlogPost[] = [];
    try {
      const url = BASE_CORS + encodeURIComponent("https://musicfy.lol/blog");
      const response = await fetch(url);
      const htmlString = await response.text();

      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, "text/html");

      const blogLinks = Array.from(
        doc.querySelectorAll("a[href^='./blog/']")
      ).slice(0, 10);

      let blogPostsFetched = 0;

      for (let i = 0; i < blogLinks.length; i++) {
        const element = blogLinks[i];

        if (blogPostsFetched >= MAX_BLOG_POSTS) {
          break;
        }

        if (!element) {
          return;
        }

        let href = element.getAttribute("href");

        if (!href) {
          return;
        }

        href = href.slice(1);

        const blogPostUrl = `https://musicfy.lol${href}`;

        const blogResponse = await fetch(
          BASE_CORS + encodeURIComponent(blogPostUrl)
        );
        const blogHtmlString = await blogResponse.text();
        const blogDoc = parser.parseFromString(blogHtmlString, "text/html");

        const title = blogDoc
          .querySelector(".blog-title")
          ?.querySelector("span")?.textContent;
        const description = blogDoc
          .querySelector(".blog-description")
          ?.querySelector("span")?.textContent;
        const content = blogDoc
          .querySelector(".blog-content")
          ?.querySelector("p")?.textContent;
        const image = blogDoc
          .querySelector(".blog-image")
          ?.querySelector("img")
          ?.getAttribute("src");
        const datePublished = blogDoc
          .querySelector(".blog-date")
          ?.querySelector("p")?.textContent;

        if (!title || !description || !content || !image || !datePublished) {
          return;
        }

        const post = {
          title,
          description,
          content,
          image,
          datePublished,
          link: blogPostUrl,
        };

        blogPostsFetched++;
        posts.push(post);
      }

      setBlogPosts(posts);
    } catch (error) {
      console.error("Error fetching or parsing blog posts:", error);
    }
  }, []);

  useEffect(() => {
    fetchAndParseBlogPosts();
  }, [fetchAndParseBlogPosts]);

  if (!blogPosts) {
    return <div>Loading</div>;
  }

  if (!blogPosts.length) {
    return null;
  }

  return (
    <div>
      <div className="text-28 font-semibold">Updates</div>
      <div className="grid mt-6 gap-4 grid-cols-1 lg:grid-cols-3">
        {blogPosts.map((post) => {
          return <BlogPostCard post={post} key={post.description} />;
        })}
      </div>
    </div>
  );
};

export default BlogPosts;
