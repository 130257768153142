import { useEffect, type MutableRefObject } from "react";

import clsx from "clsx";

import AudioPlayer, {
  type AudioPlayerRef,
} from "@musicfy/components/AudioPlayer";
import Icon from "@musicfy/components/Icon";
import { downloadFile } from "@musicfy/utils";

import { type TRecentGeneration } from "./types";

interface GenerationRowProps {
  generation: TRecentGeneration;
  isSelected: boolean;
  audioPlayersRef: MutableRefObject<AudioPlayerRef[]>;
  index: number;
  onClick: () => void;
  rowClassName?: string;
  selectedRowClassName?: string;
}

const GenerationRow = ({
  generation,
  isSelected,
  audioPlayersRef,
  index,
  onClick,
  rowClassName = "",
  selectedRowClassName = "",
}: GenerationRowProps): JSX.Element => {
  const { title, label, source, subtitle } = generation;

  useEffect(() => {
    const audioPlayer = audioPlayersRef.current[index];
    if (isSelected) {
      audioPlayer?.play();
    } else {
      audioPlayer?.pause();
    }
  }, [audioPlayersRef, index, isSelected]);

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={onClick}
      className={clsx(
        `flex items-center cursor-pointer group rounded-8 p-3 transition-all ${rowClassName}`,
        {
          "bg-white/5": isSelected,
          "bg-transparent xl:hover:bg-white/2": !isSelected,
        },
        {
          [selectedRowClassName]: isSelected,
        }
      )}
    >
      <div
        role="button"
        tabIndex={0}
        onClick={() => downloadFile({ url: source })}
        className={clsx(
          "transition-all flex-shrink-0 w-9 h-9 flex border border-transparent items-center justify-center rounded-8",
          {
            "bg-gradient-to-br from-pink-200 to-pink-400 xl:hover:border-white":
              isSelected,
            "bg-violet/70 xl:group-hover:bg-pink-200/40": !isSelected,
          }
        )}
      >
        <Icon name="download" className="w-3" />
      </div>
      <div className="mx-4 truncate">
        <div className="flex items-center gap-2">
          <div className="text-12 truncate">{title}</div>
          {!!label && (
            <div className="text-10 capitalize flex items-center justify-center py-[2px] px-2 rounded-full bg-white/15">
              {label}
            </div>
          )}
        </div>
        {!!subtitle && (
          <div className="text-gray-500 text-12 mt-[2px] truncate">
            {subtitle}
          </div>
        )}
      </div>
      <div className="ml-auto flex flex-shrink-0 items-center gap-2">
        <div
          className={clsx(
            "w-fit aspect-square border transition-all rounded-full",
            {
              "border-white/20": !isSelected,
              "border-pink-200/70": isSelected,
            }
          )}
        >
          <AudioPlayer
            lazyLoad
            type="circle"
            audioSrc={source}
            onPlay={() => {
              onClick();
            }}
            ref={(ref) => {
              if (ref) {
                audioPlayersRef.current[index] = ref;
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default GenerationRow;
