import { type MutableRefObject, useCallback, useRef } from "react";

import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { IconButton } from "@mui/material";
import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";

import AudioPlayer, {
  type AudioPlayerRef,
} from "@musicfy/components/AudioPlayer";
import { useToggleFavoriteModel } from "@musicfy/libs/ModelsProvider/hooks";
import { type IModel } from "@musicfy/libs/ModelsProvider/IModels";
import { formatNumberAsString } from "@musicfy/utils";
import { useIsMobile } from "@musicfy/utils/hooks";
import { EPublicBucketsProd } from "@musicfy/utils/hooks/useStorage";

import { FEATURE_ROUTES } from "../Navigation/constants";

const DEFAULT_THUMBNAIL = `${EPublicBucketsProd.IMAGES}default_thumbnail.png`;

const ModelCard = ({
  model,
  audioPlayersRef,
  index,
}: {
  model: IModel;
  index: number;
  audioPlayersRef: MutableRefObject<AudioPlayerRef[]>;
}): JSX.Element => {
  const isMobile = useIsMobile();
  const toggleFavoriteModel = useToggleFavoriteModel();

  const isModelSaved = model.isFavorited;
  const usageCount = model.usageCount || 0;

  const identifier =
    !model.isPrivate && !model.isCommunityModel ? model.artist : model.id;
  const secondaryIdentifier =
    !model.isPrivate && !model.isCommunityModel ? null : model.artist;
  const path = `${FEATURE_ROUTES.voice}/${identifier}${
    !!secondaryIdentifier ? `/${secondaryIdentifier}` : ""
  }`;

  return (
    <Link
      href={path}
      aria-label={`${model.artist}`}
      aria-disabled={model.isLocked}
      tabIndex={model.isLocked ? -1 : 0}
      className={clsx(
        "lg:p-3 cursor-pointer snap-start border border-transparent xl:hover:border-green transition-all box-border relative overflow-hidden p-1 lg:aspect-[7/5] aspect-square rounded-8 lg:rounded-12 flex-0-auto",
        {
          "pointer-events-none": model.isLocked,
        }
      )}
      style={{
        width: isMobile ? "44%" : `calc(25% - 16px)`,
      }}
    >
      <div className="absolute w-full left-0 top-0 z-10 h-full bg-gradient-to-b from-black/70 via-black/20 to-black/70" />
      <Image
        alt={`${model.artist} thumbnail`}
        src={model.thumbnail || DEFAULT_THUMBNAIL}
        width={300}
        height={300}
        className="h-full w-full absolute top-0 left-0 object-cover"
      />
      <div className="absolute z-10 top-2 left-2">
        {!!model.audioPreviewUrl && (
          <div
            className="rounded-full w-fit"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <AudioPlayer
              lazyLoad
              type="circle"
              onPlay={() => {
                audioPlayersRef.current.forEach((audioPlayer, i) => {
                  if (i !== index) {
                    audioPlayer.pause();
                  }
                });
              }}
              ref={(ref) => {
                if (ref) {
                  audioPlayersRef.current[index] = ref;
                }
              }}
              audioSrc={model.audioPreviewUrl}
            />
          </div>
        )}
      </div>

      <div className="w-full p-1 lg:p-3 bottom-0 left-0 absolute z-10 flex items-center justify-center">
        <div className="bg-white/20 drop-shadow backdrop-blur-lg py-2 px-4 w-full rounded-8">
          <div className="flex items-center justify-between gap-2 lg:gap-4">
            <div className="flex-shrink-1 truncate">
              <div className="text-16 font-bold truncate">{model.artist}</div>
              <div className="text-12 tracking-wide text-white/90 mt-1 truncate">
                {model.createdBy}
              </div>
            </div>
            <div className="flex flex-col-reverse lg:flex-row flex-shrink-0 items-end lg:items-center gap-2">
              <div className="tracking-wide lg:text-14 text-10">
                {formatNumberAsString(usageCount)}
              </div>
              <IconButton
                className="!m-0 !p-0"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleFavoriteModel(model.id);
                }}
              >
                {isModelSaved ? (
                  <FavoriteIcon className="cursor-pointer lg:!text-20 !text-14 transition-all xl:hover:text-white text-green" />
                ) : (
                  <FavoriteBorderIcon className="cursor-pointer lg:!text-20 !text-14 transition-all text-white xl:hover:text-green" />
                )}
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

const ModelsCarousel: React.FC<{ models: IModel[]; title: string }> = ({
  models,
  title,
}): JSX.Element => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const audioPlayersRef = useRef<AudioPlayerRef[]>([]);

  const scroll = useCallback((direction: "right" | "left") => {
    if (!scrollContainerRef.current) return;

    const scrollContainer = scrollContainerRef.current;

    const scrollLeft = scrollContainer.scrollLeft;
    const clientWidth = scrollContainer.clientWidth;

    const scrollAmount =
      direction === "right"
        ? scrollLeft + clientWidth
        : scrollLeft - clientWidth;

    scrollContainer.scrollTo({
      left: scrollAmount,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="text-28 font-semibold">{title}</div>
        <div className="lg:flex hidden items-center gap-4 mr-4">
          <div
            onClick={() => scroll("left")}
            role="button"
            tabIndex={0}
            className="!text-white transition-all bg-gray-1000/50 xl:hover:bg-gray-1000 xl:hover:border-white/50 cursor-pointer border border-white/20 flex items-center justify-center w-10 aspect-square rounded-full"
          >
            <ChevronLeft />
          </div>
          <div
            onClick={() => scroll("right")}
            role="button"
            tabIndex={0}
            className="!text-white transition-all bg-gray-1000/50 xl:hover:bg-gray-1000 xl:hover:border-white/50 cursor-pointer border border-white/20 flex items-center justify-center w-10 aspect-square rounded-full"
          >
            <ChevronRight />
          </div>
        </div>
      </div>
      <div
        ref={scrollContainerRef}
        className="mt-6 w-full flex flex-nowrap lg:gap-4 gap-2 overflow-auto pb-3 snap-x"
      >
        {models.map((model, index) => {
          return (
            <ModelCard
              audioPlayersRef={audioPlayersRef}
              key={model.id}
              index={index}
              model={model}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ModelsCarousel;
