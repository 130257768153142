import { SubscriptionPlans } from "@prisma/client";

import { MODEL_TRAINING_MAX_QUOTA } from "@musicfy/contants/Features";

import Button from "../Button";
import Icon from "../Icon";
import { FEATURE_ROUTES } from "../Navigation/constants";

const CreateVoice = (): JSX.Element => {
  return (
    <div className="rounded-[18px] relative overflow-hidden flex flex-grow min-h-52 flex-col justify-between lg:px-12 lg:py-8 p-6 border border-white/20">
      <div className="w-full h-full bg-white/5 backdrop-blur-3xl absolute left-0 top-0 z-20" />
      <div className="absolute h-full aspect-square rounded-full bg-purple-light/50 z-10" />
      <div className="absolute h-full -top-10 left-1/3 aspect-square rounded-full bg-pink-200/50 z-10" />
      <div className="absolute h-full right-0 -bottom-10 aspect-square rounded-full bg-purple/50 z-10" />
      <div className="relative z-30 h-full">
        <div className="flex xl:flex-row flex-col items-start justify-between h-full gap-6">
          <div className="flex xl:flex-col h-full justify-between w-full xl:w-auto xl:items-start items-center">
            <div className="lg:text-32 text-22 font-semibold capitalize">
              Create your own AI voice
            </div>
            <Button
              className="lg:!px-12 ml-2 lg:ml-0 whitespace-nowrap"
              path={FEATURE_ROUTES.voices}
            >
              + Add Voice
            </Button>
          </div>
          <div className="w-full grid grid-cols-2 cursor-default">
            <div className="flex flex-col items-center">
              <div className="text-center text-16 w-full py-2 bg-gray-1000/60 rounded-l-full border border-white/20">
                Starter
              </div>
              <div className="flex flex-col gap-3 mt-4">
                <div className="flex items-center gap-3">
                  <Icon name="rocket" className="w-5" />
                  <div className="text-14 text-white/80">Standard Speed</div>
                </div>
                <div className="flex items-center gap-3">
                  <Icon name="cube" className="w-5" />
                  <div className="text-14 text-white/80">
                    {MODEL_TRAINING_MAX_QUOTA[SubscriptionPlans.starter]} Custom
                    Models
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center">
              <div className="text-center text-16 w-full py-2 bg-gray-1000/60 rounded-r-full border border-white/20">
                Professional
              </div>
              <div className="flex flex-col gap-3 mt-4">
                <div className="flex items-center gap-3">
                  <Icon name="rocket" className="w-5" />
                  <div className="text-14 text-white/80">High Speed</div>
                </div>
                <div className="flex items-center gap-3">
                  <Icon name="cube" className="w-5" />
                  <div className="text-14 text-white/80">
                    {MODEL_TRAINING_MAX_QUOTA[SubscriptionPlans.pro]} Custom
                    Models
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateVoice;
