import { useEffect, useRef, useState } from "react";

import { type AudioPlayerRef } from "@musicfy/components/AudioPlayer";
import Icon from "@musicfy/components/Icon";

import GenerationRow from "./GenerationRow";
import { type TRecentGeneration } from "./types";

interface RecentGenerationsProps {
  generations: TRecentGeneration[];
  rowClassName?: string;
  selectedRowClassName?: string;
}

const RecentGenerations = ({
  generations,
  rowClassName = "",
  selectedRowClassName = "",
}: RecentGenerationsProps): JSX.Element => {
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  const audioPlayersRef = useRef<AudioPlayerRef[]>([]);

  useEffect(() => {
    setSelectedIndex(-1);
  }, [generations]);

  return (
    <div>
      <div className="flex flex-col gap-2 mt-3">
        {!!generations.length ? (
          <>
            {generations.map((generation, index) => {
              const isSelected = index === selectedIndex;
              return (
                <GenerationRow
                  rowClassName={rowClassName}
                  selectedRowClassName={selectedRowClassName}
                  key={generation.id}
                  isSelected={isSelected}
                  audioPlayersRef={audioPlayersRef}
                  generation={generation}
                  index={index}
                  onClick={() => {
                    setSelectedIndex(index);
                  }}
                />
              );
            }, [])}
          </>
        ) : (
          <div className="px-2 flex items-center gap-3">
            <div className="rounded-8 w-10 h-10 bg-violet/40 flex items-center justify-center">
              <Icon name="logo-white" className="invert-[40%]" />
            </div>
            <div className="capitalize text-14 font-medium text-gray-400">
              No Conversions Yet
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RecentGenerations;
