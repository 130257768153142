import axios from "axios";

import { env } from "@musicfy/env.mjs";

export enum ModalFunction {
  DELETE_MODEL = "delete-model",
  INFERENCE = "process-inference",
  TRAINING = "process-training",
  UPLOAD_MODEL = "upload-model",
  YOUTUBE_TO_MP3 = "youtube-to-mp3",
  TEXT_TO_MUSIC = "text-to-music",
  GENERATE_VIDEO = "generate-video",
  GENERATE_STEMS = "generate-stems",
}

export enum ModalApp {
  SERVER = "server",
}

export const MODAL_FUNCTION_TO_APP = {
  [ModalFunction.DELETE_MODEL]: ModalApp.SERVER,
  [ModalFunction.INFERENCE]: ModalApp.SERVER,
  [ModalFunction.TRAINING]: ModalApp.SERVER,
  [ModalFunction.UPLOAD_MODEL]: ModalApp.SERVER,
  [ModalFunction.YOUTUBE_TO_MP3]: ModalApp.SERVER,
  [ModalFunction.TEXT_TO_MUSIC]: ModalApp.SERVER,
  [ModalFunction.GENERATE_VIDEO]: ModalApp.SERVER,
  [ModalFunction.GENERATE_STEMS]: ModalApp.SERVER,
} as const;

const modalAxios = (functionName: ModalFunction) => {
  const isDev = env.NEXT_PUBLIC_VERCEL_ENV === "development";
  const isStaging = env.NEXT_PUBLIC_VERCEL_ENV === "preview";

  const app = MODAL_FUNCTION_TO_APP[functionName];

  let url = "";

  if (isDev) {
    url = `https://sovits-app-dev--${app}-${functionName}-dev.modal.run/`;
  } else if (isStaging) {
    url = `https://sovits-app-dev--${app}-${functionName}.modal.run/`;
  } else {
    url = `https://sovits-app-prod--${app}-${functionName}.modal.run/`;
  }

  const baseAxios = axios.create({
    baseURL: url,
    timeout: 6 * 60 * 1000 + 15000, // 6 minutes and 15 seconds
  });

  return baseAxios;
};

export default modalAxios;
