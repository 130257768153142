import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useSession } from "next-auth/react";

import {
  EFeatures,
  FEATURE_ROUTES,
} from "@musicfy/components/Navigation/constants";

import Button from "../Button";

const Welcome = (): JSX.Element => {
  const { data } = useSession();

  const name = data?.user.name;
  const firstLine = name ? `Hey ${name},` : "Hey there,";

  return (
    <div className="rounded-[18px] flex-grow min-h-52 flex flex-col justify-between lg:px-12 lg:py-8 p-6 bg-gradient-to-tr from-pink-200 via-pink-300 to-purple-light/50 bg-gray-1000">
      <div>
        <div className="font-semibold text-18">{firstLine}</div>
        <div className="font-bold text-32 mt-2">Welcome Back!</div>
      </div>

      <Button
        path={FEATURE_ROUTES[EFeatures.VOICE]}
        variant="outlined"
        style="secondary"
        className="!text-white !font-medium !bg-white/5 xl:hover:!bg-white/15"
        postIcon={<ArrowForwardIcon />}
      >
        Convert Voice
      </Button>
    </div>
  );
};

export default Welcome;
