import { ModelStatusTypes, type Models } from "@prisma/client";

import { type TQueryOutput } from "@musicfy/utils";

export type IModel = TQueryOutput<"models", "getModels">[number] & {
  trendingScore?: number;
};

export const DEFAULT_MODEL: Models = {
  id: "",
  userId: "",
  artist: "",
  status: ModelStatusTypes.pending,
  f0Method: "rmvpe",
  epochCount: 200,
  batchSize: 16,
  sampleRate: "40000",
  version: 2.0,
  isPrivate: true,
  isCopyrightFree: true,
  isLocked: false,
  isInstrumental: false,
  isCommunityModel: false,
  isClusterTrained: false,
  createdBy: null,
  genre: null,
  thumbnail: null,
  modelAId: null,
  modelBId: null,
  usageCount: 0,
  audioPreviewUrl: null,
  gender: "",
  trendingUsageCount: null,
  createdAt: new Date(),
  updatedAt: new Date(),
};
